import styled from "styled-components";
import { colors } from "../element/elements.js";

export const Styles = styled.div`
  .breadcrumb-area {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 60px 0;
    position: relative;
    height: 525px;
    top: -1px;

    @media (max-width: 1199px) {
      height: 350px;
    }

    @media (max-width: 992px) {
      height: 250px;
    }

    @media (max-width: 767px) {
      height: 210px;
    }

    @media (max-width: 500px) {
      height: 220px;
    }

    &:before {
      position: absolute;
      content: "";
      background: #2c0c0f;
      opacity: 0.4;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    .breadcrumb-box {
      position: relative;
      border-radius: 8px;
      margin-top: 175px;
      z-index: 2;
      width: fit-content;
      padding: 10px;
      border-radius: 12px;
      backdrop-filter: blur(2px);

      @media (max-width: 1199px) {
        margin-top: 96px;
      }

      @media (max-width: 992px) {
        margin-top: 32px;
      }

      @media (max-width: 500px) {
        margin-top: 16px;
      }

      h2.breadcrumb-title {
        color: #ffffff;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 5px;

        @media (max-width: 767px) {
          font-size: 20px;
        }
      }

      nav {
        ol.breadcrumb {
          display: inline-flex;
          padding: 0;
          margin-bottom: 0;
          background-color: transparent;
          border-radius: 0;

          li.breadcrumb-item {
            a {
              color: ${colors.bg2};
              font-weight: 600;

              &:hover {
                color: #ffffff;
              }
            }
          }

          li.breadcrumb-item.active {
            color: ${colors.border3};
            font-weight: 600;

            &::before {
              color: ${colors.border3};
            }
          }
        }
      }
    }

    .centered-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 100%;

      .breadcrumb-pre-title {
        font-size: 1.2rem;
        color: ${colors.bg2};
        margin-bottom: 10px;
      }

      .register-button {
        margin-top: 20px;
      }
    }

    @media (max-width: 1000px) {
      padding: 30px 0;
      margin-top: 0;
    }
  }
`;
