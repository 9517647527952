import { Styles } from "../apply/styles/apply.js";
import Button from "../../components/shared/Button.jsx";
import BreadcrumbBox from "../../components/common/Breadcrumb.jsx";
import { useTranslation } from "react-i18next";
import React, { useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FormWithInputAndLabel from "../../components/shared/FormWithInputAndLabel.jsx";
import { useBeManualFetcher } from "../../hooks/useBeManualFetcher.js";
import { cleanUpObject } from "../../utilities/objectUtils.js";
import AlertComponent from "../../components/shared/AlertComponent.js";
import opendaysService from "../../services/opendaysService.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const validationSchema = yup.object().shape({
  attendees: yup.string().required("This field is required"),
  date: yup.string().required("Date selection is required"),
  school: yup.string(),
  hear: yup
    .string()
    .oneOf(
      ["Social Media", "School Visit", "Advertisement", "Friend"],
      "Invalid option"
    )
    .required("This field is required"),
  interest: yup
    .string()
    .oneOf(["Science", "Business", "Pharmacy"], "Invalid option")
    .required("This field is required"),
  message: yup.string(),
});

const OpenDaysForm = () => {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });
  const [onFetch, isLoading] = useBeManualFetcher();
  const formRef = useRef();

  const onFormSubmit = async (form) => {
    const {
      firstName,
      lastName,
      middleName,
      email,
      phoneNumber,
      region,
      city,
      school,
      yearOfStudy,
      ieltsScore,
      secondPhoneNumber,
      socialMediaPermission,
    } = form;

    const filteredObject = cleanUpObject({
      firstName,
      lastName,
      middleName,
      email,
      phoneNumber,
      region,
      city,
      school,
      yearOfStudy,
      ieltsScore,
      secondPhoneNumber,
      socialMediaPermission,
    });

    return await onFetch({
      action: () => opendaysService.registerOpendays(filteredObject),
      onLoad: () => {
        formRef.current.reset();

        AlertComponent({
          title: "You have successfully submitted your olumpiad application!",
          text: "Our Admission team will send you e-mail as soon as possible.",
          icon: "success",
        });
      },
    });
  };

  const minTime = new Date();
  minTime.setHours(9, 0, 0, 0);

  const maxTime = new Date();
  maxTime.setHours(18, 0, 0, 0);

  return (
    <Styles>
      <div className="main-wrapper registration-page">
        <BreadcrumbBox
          title={t("Open Days Booking Form")}
          image="/assets/images/opendays-2.jpg"
        />
        <section className="registration-area application-form">
          <Container>
            <Row>
              <Col md="12">
                <div className="registration-box">
                  <div className="registration-title text-center">
                    <h3>{t("Registration Form")}</h3>
                    <div className="registration-note">
                      {t(
                        "If you have any question, please feel free to contact us through Telegram bot?"
                      )}{" "}
                      <a
                        href="https://t.me/ptuuz_bot"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t("Click Here")}
                      </a>
                    </div>
                  </div>
                  <form
                    id="form_registration"
                    className="form"
                    onSubmit={handleSubmit(onFormSubmit)}
                    ref={formRef}
                  >
                    <Row>
                      <Col md="6" lg="6">
                        <FormWithInputAndLabel
                          label={"Number of attendees"}
                          type="text"
                          placeholder="Number of attendees"
                          name="attendees"
                          id="attendees"
                          register={register}
                          errors={errors}
                          required
                        />
                      </Col>
                      <Col md="6" lg="6">
                        <div className="form-control mb-4">
                          <label htmlFor="date" className="mr-2 d-block">
                            {t("Preferred date and time for visit")}
                            <strong className="text-danger">*</strong>
                          </label>
                          <Controller
                            name="date"
                            control={control}
                            defaultValue={new Date()}
                            render={({ field }) => (
                              <DatePicker
                                {...field}
                                selected={field.value}
                                onChange={(date) => field.onChange(date)}
                                dateFormat="yyyy-MM-dd HH:mm"
                                showTimeSelect
                                timeIntervals={15}
                                minDate={new Date()}
                                minTime={minTime}
                                maxTime={maxTime}
                              />
                            )}
                          />
                          {errors?.["date"] && (
                            <p className="invalid">
                              {errors?.["date"]?.message}
                            </p>
                          )}
                        </div>
                      </Col>
                      <Col md="6" lg="6">
                        <FormWithInputAndLabel
                          label={"School / Institution name"}
                          type="text"
                          placeholder="School / Institution name"
                          name="school"
                          id="school"
                          register={register}
                          errors={errors}
                        />
                      </Col>
                      <Col md="6">
                        <div className="form-control mb-4">
                          <label htmlFor="hear">
                            {t("How did you hear about us?")}
                            <strong className="text-danger">*</strong>
                          </label>
                          <select
                            placeholder={t("Select one...")}
                            className="select h-40px"
                            name="hear"
                            {...register("hear")}
                          >
                            <option value="Social Media">Social Media</option>
                            <option value="School Visit">School Visit</option>
                            <option value="Advertisement">Advertisement</option>
                            <option value="Friend">Friend</option>
                          </select>
                          {errors?.["hear"] && (
                            <p className="invalid">
                              {errors?.["hear"]?.message}
                            </p>
                          )}
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-control mb-4">
                          <label htmlFor="interest">
                            {t("Areas of interest")}
                            <strong className="text-danger">*</strong>
                          </label>
                          <select
                            placeholder={t("Select interest...")}
                            className="select h-40px"
                            name="interest"
                            {...register("interest")}
                          >
                            <option value="Science">Science</option>
                            <option value="Business">Business</option>
                            <option value="Pharmacy">Pharmacy</option>
                          </select>
                          {errors?.["interest"] && (
                            <p className="invalid">
                              {errors?.["interest"]?.message}
                            </p>
                          )}
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-control mb-4">
                          <label htmlFor="message">
                            {t("Any specific questions or requests?")}
                          </label>
                          <textarea
                            name="message"
                            // cols={54}
                            id="contact_message"
                            placeholder={t("Enter Message")}
                            {...register("message", {
                              required: t("Field cannot be empty"),
                              minLength: {
                                value: 10,
                                message: t(
                                  "Minimum length should be more than 10 characters"
                                ),
                              },
                            })}
                          ></textarea>
                          {errors?.["message"] && (
                            <p className="invalid">
                              {errors?.["message"]?.message}
                            </p>
                          )}
                        </div>
                      </Col>
                      <Col lg="12" className="d-flex justify-content-center">
                        <Button
                          type={"submit"}
                          className="px-5 mt-3"
                          text={"Submit"}
                          disabled={isLoading}
                          isLoading={isLoading}
                          eventName={"student_app_submit"}
                        />
                      </Col>
                    </Row>
                  </form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </Styles>
  );
};

export default OpenDaysForm;
