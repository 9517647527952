import styled from "styled-components";
import { colors } from "../../../components/common/element/elements";

export const Styles = styled.div`
  .main-wrapper {
    padding: 40px 20px;
    max-width: 1200px;
    margin: 0 auto;
  }

  .olympiad-header {
    text-align: center;
    margin-bottom: 40px;
    
    h1 {
      font-size: 2.5rem;
      color: #1a1a1a;
      margin-bottom: 10px;
      font-weight: 700;
    }

    p {
      font-size: 1.2rem;
      color: #666;
    }
  }

  .olympiad-intro {
    margin: 0 auto 50px;

    p {
      margin-bottom: 20px;
      line-height: 1.6;
      font-size: 1.1rem;
      color: #333;
    }
  }

  .olympiad-stages {
    margin-bottom: 50px;

    h2 {
      margin-bottom: 30px;
      font-size: 1.8rem;
      color: #1a1a1a;
    }

    .stage {
      background: #f8f9fa;
      border-radius: 10px;
      padding: 25px;
      margin-bottom: 20px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

      h3 {
        color: #2c3e50;
        margin-bottom: 15px;
        font-size: 1.4rem;
      }

      p {
        margin-bottom: 15px;
        line-height: 1.5;
        color: #4a4a4a;

        &:last-child {
          margin-bottom: 0;
        }

        strong {
          color: #2c3e50;
        }
      }
    }
  }

  .olympiad-prizes {
    background: #f1f7ff;
    padding: 30px;
    border-radius: 10px;
    margin-bottom: 50px;
    text-align: center;

    h2 {
      color: #1a1a1a;
      margin-bottom: 20px;
      font-size: 1.8rem;
    }

    p {
      color: #333;
      line-height: 1.6;
      font-size: 1.1rem;
    }
  }

  .olympiad-registration {
    text-align: center;
    margin-bottom: 40px;

    h2 {
      color: #1a1a1a;
      margin-bottom: 25px;
      font-size: 1.8rem;
    }
  }

  .olympiad-registration {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .register-btn {
      text-align: center;
      margin-bottom: 20px;
    }
  }

  .olympiad-link {
    color: ${colors.bg1};
    font-weight: 600;
    text-decoration: underline;
  }

  /* Responsive Design */
  @media (max-width: 768px) {
    .main-wrapper {
      padding: 20px 15px;
    }

    .olympiad-header {
      h1 {
        font-size: 2rem;
      }
    }

    .olympiad-stages {
      .stage {
        padding: 20px;
      }
    }
  }

  @media (max-width: 480px) {
    .olympiad-header {
      h1 {
        font-size: 1.8rem;
      }
    }

    .olympiad-intro p,
    .olympiad-stages .stage p {
      font-size: 1rem;
    }
  }
`;