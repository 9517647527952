import React from "react";
import { useNavigate } from "react-router-dom";
import { Styles } from "./styles/olympiad.js";
import Button from "../../components/shared/Button.jsx";
import BreadcrumbBox from "../../components/common/Breadcrumb.jsx";
import { useTranslation } from "react-i18next";
import { useLanguageDetector } from "../../hooks/useLanguageDetector";
import EnglishData from "./data/olympiad.json";
import RussianData from "./data/olympiad-ru.json";
import UzbekData from "./data/olympiad-uz.json";

const OlympiadPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [data] = useLanguageDetector(EnglishData, RussianData, UzbekData);

  return (
    <Styles>
      <BreadcrumbBox
        title={data.pageTitle}
        image="/assets/images/banner-2.jpg"
        preTitle={data.preTitle}
        centerTitle={true}
        registerButton={true}
        registerLink="/olympiad/form"
      />
      <div className="main-wrapper olympiad-page">
        {/* Introduction Section */}
        <div className="olympiad-intro">
          <p>{data.introduction.paragraph1}</p>
          <p>{data.introduction.paragraph2}</p>
        </div>

        {/* Stages Section */}
        <div className="olympiad-stages">
          <h2>{data.stages.title}</h2>

          {/* Stage 1 */}
          <div className="stage">
            <h3>{data.stages.stage1.title}</h3>
            <p>{data.stages.stage1.description}</p>
            <p>
              <strong>{t("Dates:")}</strong> {data.stages.stage1.dates}
            </p>
            <p>{data.stages.stage1.additionalInfo}</p>
            <p>
              <a
                href={data.stages.stage1.sampleQuestionsLink.link}
                target="_blank"
                rel="noopener noreferrer"
                className="olympiad-link"
              >
                {data.stages.stage1.sampleQuestionsLink.text}
              </a>
            </p>
          </div>

          {/* Stage 2 */}
          <div className="stage">
            <h3>{data.stages.stage2.title}</h3>
            <p>{data.stages.stage2.description}</p>
            <p>{data.stages.stage2.location}</p>
            <p>
              <strong>{t("Date:")}</strong> {data.stages.stage2.date}
            </p>
            <p>
              <strong>{t("Language:")}</strong> {data.stages.stage2.language}
            </p>
          </div>
        </div>

        {/* Prizes Section */}
        <div className="olympiad-prizes">
          <h2>{data.prizes.title}</h2>
          <p>{data.prizes.description}</p>
        </div>

        {/* Registration Section */}
        <div className="olympiad-registration">
          <h2>{data.registration.title}</h2>
          <Button
            className="register-btn"
            onClick={() => navigate("/olympiad/form")}
            text={data.registration.buttonText}
          />
          <p>{data.registration.note}</p>
        </div>
      </div>
    </Styles>
  );
};

export default OlympiadPage;