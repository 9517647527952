import styled from "styled-components";
import { colors } from "../common/element/elements.js";

export const Styles = styled.div`
  .hero-slider-area {
    top: -1px;
    position: relative;

    .swiper-container {
      position: relative;

      .swiper-wrapper {
        .swiper-slide.slider-item {
          position: relative;

          .image-container {
            display: block;
            overflow: hidden;
            position: relative;
            max-height: 800px;

            .mask {
              -webkit-mask-image: url(/assets/images/hero-mask.png);
              mask-image: url(/assets/images/hero-mask.png);
              mask-repeat: no-repeat;
              mask-size: cover;
              mask-position: center;
              -webkit-mask-repeat: no-repeat;
              -webkit-mask-size: cover;
              -webkit-mask-position: center;

              .slider-image {
                height: 80vh;
                width: 100%;
                margin-top: 0;
                transition: all 6000ms linear;
                transform: scale(1);
                background-size: cover;
                background-position: center;
                object-fit: cover;
              }
            }

            @media (max-width: 767px) {
              height: 550px;
            }

            @media (max-width: 575px) {
              height: 460px;
            }
          }

          .slider-table {
            width: 100vw;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 9999;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .slider-tablecell {
              .slider-box {
                display: flex;
                flex-direction: column;
                justify-content: end;

                .slider-title {
                  p {
                    font-size: 18px;
                    color: ${colors.bg2};
                    margin-bottom: 8px;
                    font-weight: 600;

                    @media (max-width: 1199px) {
                      font-size: 16px;
                    }

                    @media (max-width: 767px) {
                      font-size: 14px;
                    }
                  }
                }

                .slider-desc {
                  h1 {
                    font-size: 40px;
                    color: #ffffff;
                    max-width: 800px;
                    margin-bottom: 32px;
                    font-weight: 600;

                    @media (max-width: 1199px) {
                      font-size: 24px;
                    }

                    @media (max-width: 767px) {
                      font-size: 18px;
                    }
                  }
                }

                .slider-btn {
                  display: flex;
                  justify-content: end;
                  column-gap: 20px;

                  @media (max-width: 575px) {
                    align-items: center !important;
                  }
                }

                .slider-btn-left-aligned {
                  display: flex;
                  justify-content: start;
                  column-gap: 20px;

                  @media (max-width: 575px) {
                    justify-content: center !important;
                  }
                }
                @media (max-width: 575px) {
                  text-align: center !important;
                }
              }

              .slider-box2 {
                .slider-desc {
                  h1 {
                    margin-left: auto;
                  }
                }
              }

              .text-right {
                text-align: right !important;
              }
            }
          }
        }

        .swiper-slide.slider-item.swiper-slide-visible.swiper-slide-active {
          .image-container {
            img.slider-image {
              transform: scale(1.15);
            }
          }

          .slider-table {
            .slider-tablecell {
              .slider-title {
                animation-name: fadeInDown;
                animation-duration: 1s;
                animation-delay: 0.2s;
                animation-fill-mode: both;
              }

              .slider-desc {
                animation-name: fadeInUp;
                animation-duration: 1.5s;
                animation-delay: 0.2s;
                animation-fill-mode: both;
              }

              .slider-btn {
                animation-name: fadeInUp;
                animation-duration: 2s;
                animation-delay: 0.2s;
                animation-fill-mode: both;
              }
            }
          }
        }
      }

      .swiper-btn {
        position: absolute;
        top: 85%;
        left: 40px;
        font-size: 24px;
        color: ${colors.border1};
        text-align: center;
        padding: 10px 12px;
        border-radius: 8px;
        transition: all 0.3s ease;
        margin-top: -35px;
        z-index: 111;

        i {
        }

        &:hover {
          background: ${colors.gr_bg};
          color: #ffffff;
        }
      }

      .swiper-btn.slider-button-next {
        left: inherit;
        right: 40px;
      }
    }
    @media (max-width: 999px) {
      margin: 0;
    }
  }
`;
